import React, { useRef, useState, useEffect } from "react";
import api from "../api";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

export default function PrivacyPolicy() {
    const { id } = useParams();

    const tel = id || "87475315558";

    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");

    useEffect(() => {
        const formData = new FormData();
        formData.append("phone", tel);

        api.post("/getFranchiseeByPhone", formData, {
            headers: { "Content-Type": "application/json" },
        })
            .then(({ data }) => {
                setPhone(
                    `+7 ${data.phone.substring(1, 4)} ${data.phone.substring(
                        4,
                        7
                    )} ${data.phone.substring(7, 9)} ${data.phone.substring(9)}`
                );
                setMail(data.mail);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [tel]);

    const footerRef = useRef(null);

    const paymentTerms1 = `Добро пожаловать в раздел, где вы можете ознакомиться с нашими правилами доставки, порядком оплаты, условиями возврата денежных средств и политикой конфиденциальности. Мы стремимся обеспечить удобство и безопасность для всех наших клиентов, поэтому просим вас внимательно изучить приведенную ниже информацию.

Платежи. Оплата банковской картой онлайн

Наш сайт подключен к интернет-эквайрингу, и Вы можете оплатить Услугу банковской картой Visa, Mastercard и UnionPay. После подтверждения выбранного Товара либо услуги откроется защищенное окно с платежной страницей АО ForteBank, где Вам необходимо ввести данные Вашей банковской карты. Для дополнительной аутентификации держателя карты используется протокол 3-D Secure. Если Ваш Банк-эмитент поддерживает данную технологию, Вы будете перенаправлены на его сервер для прохождения дополнительной идентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.

Услуга онлайн-оплаты осуществляется в соответствии с правилами Международных платежных систем Visa, MasterCard и UnionPay на принципах соблюдения конфиденциальности и безопасности совершения платежа, для этого используются самые актуальные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется в защищенном окне на платежной странице АО ForteBank.

В поля на платежной странице требуется ввести номер карты, имя владельца карты, срок действия карты, трехзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные отображены на поверхности банковской карты.

CVV2/ CVC2 — это трехзначный код безопасности, находящийся на оборотной стороне карты.

Далее в том же окне откроется страница Вашего банка-эмитента для ввода 3-D Secure кода. В случае, если у вас не настроен статичный 3-D Secure, он будет отправлен на ваш номер телефона посредством SMS. Если 3-D Secure код к Вам не пришел, то следует обратится в ваш банк-эмитент.

3-D Secure — это самая современная технология обеспечения безопасности платежей по картам в сети интернет. Позволяет однозначно идентифицировать подлинность держателя карты, осуществляющего операцию, и максимально снизить риск мошеннических операций по карте.

Гарантии безопасности

АО ForteBank защищает и обрабатывает данные Вашей банковской карты по стандарту безопасности PCI DSS 3.0. Передача информации в платежный шлюз происходит с применением технологии шифрования SSL. Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим наивысший уровень надежности. АО ForteBank не передает данные Вашей карты нам и иным третьим лицам. Для дополнительной аутентификации держателя карты используется протокол 3-D Secure.

В случае, если у Вас есть вопросы по совершенному платежу, Вы можете обратиться в службу поддержки клиентов платежного сервиса по электронной почте info@tibetskaya.kz.

Безопасность онлайн-платежей

Предоставляемая Вами персональная информация (имя, адрес, телефон, e-mail, номер кредитной карты) является конфиденциальной и не подлежит разглашению. Данные Вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.

Безопасность обработки Интернет-платежей гарантирует АО ForteBank. Все операции с платежными картами происходят в соответствии с требованиями VISA International, MasterCard, UnionPay и других платежных систем. При передаче информации используются специализированные технологии безопасности карточных онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном сервере процессинговой компании.

Оплата платежными картами безопасна, потому что:
Система авторизации гарантирует покупателю, что платежные реквизиты его платежной карты (номер, срок действия, CVV2/CVC2) не попадут в руки мошенников, так как эти данные не хранятся на сервере авторизации и не могут быть похищены.
Покупатель вводит свои платежные данные непосредственно в системе авторизации АО ForteBank, а не на сайте интернет-магазина, следовательно, платежные реквизиты карточки покупателя не будут доступны третьим лицам.
`

    const paymentTerms2 = `Правила доставки

1. Общие положения
1.1. Поставка питьевой воды (далее – «Товар») осуществляется ТОО «Partner’s Mountain Water Co» (далее – «Поставщик») на основании заключенного договора поставки.
1.2. Товар представляет собой питьевую воду торговой марки «Тибетская» в пластиковых бутылях объёмом 12,5 л и 18,9 л.

2. Оформление и условия доставки
2.1. Доставка Товара в пределах города Алматы осуществляется Поставщиком бесплатно. Пользователь самостоятельно выбирает дату и время доставки из доступных вариантов при оформлении Заказа.
2.2. Адрес доставки указывается клиентом при оформлении заказа.
2.3. Во время доставки клиент обязан проверить количество бутилированной воды, а также при необходимости передать Поставщику порожнюю оборотную тару.

3. Оборотная тара
3.1. Пластиковая бутыль объемом 12,5 л и 18,9 л является оборотной тарой. Её стоимость составляет 2 500 и 4 500 тенге за 1 шт.
3.2. После оплаты тары право собственности переходит к клиенту.
3.3. Клиент обязуется использовать бутыли только по назначению и бережно. При обнаружении Поставщиком повреждения тары, несоответствующего стандартам, Поставщик имеет право не принимать такую бутыль на обмен. В этом случае Заказчик может либо предоставить другую, исправную тару, либо приобрести у Поставщика новую по полной стоимости.

4. Стоимость и порядок оплаты
4.1. Цена за одну бутыль воды объемом 18,9 л составляет 1 300 тенге (без учета НДС). Цена за одну бутыль воды объемом 12,5 л составляет 900 тенге (без учета НДС).
4.2. Оплата заказанного Товара по итогам каждого месяца в период с 1-го по 10-е число следующего месяца применяется только к тем клиентам–юридическим лицам, которые заключили с Поставщиком договор поставки с соответствующими условиями постоплаты. Для физических лиц данное правило не действует; условия оплаты для них определяются в порядке, предусмотренном для каждого конкретного случая (например, оплата при доставке).
4.3. В случае задержки оплаты Поставщик вправе приостановить дальнейшие поставки до момента полного погашения задолженности.

5. Качество Товара
5.1. Качество поставляемой воды соответствует действующим стандартам и требованиям (СТ РК 1432–2005 и т. д.).
5.2. При обнаружении несоответствия качества Товара клиент вправе отказаться от его приемки и/или потребовать замену.

6. Ответственность сторон
6.1. Поставщик несет ответственность за своевременную доставку и качество воды в соответствии с условиями договора и требованиями законодательства РК.
6.2. Клиент обязан обеспечить доступ для приема Товара и своевременно производить оплату.
6.3. При обнаружении Поставщиком повреждения оборотной тары, несоответствующего стандартам, Поставщик имеет право не принимать такую бутыль на обмен. В этом случае Заказчик может либо предоставить другую, исправную тару, либо приобрести у Поставщика новую по полной стоимости. Кроме того, при утере, повреждении или нецелевом использовании оборотной тары Поставщик вправе требовать от Заказчика возмещения причинённого ущерба.

7. Срок действия правил
7.1. Настоящие правила поставки действуют в рамках заключенного с клиентом договора и могут быть изменены или дополнены по соглашению сторон.
7.2. В части финансовых расчетов обязательства сторон сохраняются до полного исполнения.

8. Прочие условия
8.1. Все споры и разногласия, возникающие в процессе поставки, решаются путем переговоров или, при невозможности урегулирования, в судебном порядке в соответствии с законодательством Республики Казахстан.
8.2. Поставщик гарантирует сохранение конфиденциальности информации, полученной от клиента, за исключением случаев, предусмотренных законом.

Возврат денежных средств

При проведении онлайн-оплаты посредством платежных карт не допускается возврат наличными денежными средствами. Порядок возврата регулируется правилами международных платежных систем:
Потребитель вправе отказаться от товара в любое время до его передачи, после передачи товара отказ необходимо оформить в течение 14 дней;
Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;
Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;
При отказе от товара со стороны потребителя продавец должен вернуть ему денежную сумму, уплаченную потребителем, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования.
Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию компанией на электронный адрес, и отправить его вместе с приложением копии документа, удостоверяющего личность, по адресу info@tibetskaya.kz.

Возврат денежных средств будет осуществлен на банковскую карту в течение 3 рабочих дней со дня получения «Заявление о возврате денежных средств» Компанией.

Для возврата денежных средств по операциям, проведенным с ошибками, необходимо обратиться с письменным заявлением и приложением копии документа, удостоверяющего личность, и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу info@tibetskaya.kz.

Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.

Случаи отказа в совершении платежа:
банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк-эмитент;
недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на платежной карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;
данные банковской карты введены неверно;
истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк-эмитент.
По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обратиться по следующим телефонам:+7(727)3172737.

Конфиденциальность
Политика конфиденциальности
Настоящая политика конфиденциальности действует в отношении всей информации, которую интернет-проект www.tibetskaya.kz и клиентское приложение "Аквамаркет Тибетская" (далее – "Приложение") могут получить от Пользователя во время его использования.
1. Термины
1.1. "Сайт" — интернет-проект www.tibetskaya.kz, предоставляющий услуги Пользователям.
1.2. "Приложение" — клиентское приложение "Аквамаркет Тибетская" для заказа бутилированной воды, устанавливаемое на мобильные устройства Пользователя.
1.3. "Администрация" — лица, уполномоченные ТОО "Partner’s Mountain Water Co" на управление Сайтом и Приложением, которые организуют и/или осуществляют обработку персональных данных.
1.4. "Персональные данные" — сведения, относящиеся к определенному или определяемому физическому лицу.
1.5. "Регистрационные данные" — данные, предоставленные при регистрации в Приложении или на Сайте.
1.6. "Обработка данных" — накопление, хранение, изменение, использование, распространение, обезличивание, блокирование и уничтожение регистрационных/персональных данных.
1.7. "Cookies" — небольшие фрагменты данных, сохраняемые на устройстве Пользователя для улучшения работы Сайта и Приложения.
1.8. "IP-адрес" — уникальный сетевой адрес устройства Пользователя.
2. Общие положения
2.1. Использование Сайта или Приложения Пользователем означает согласие с настоящей Политикой конфиденциальности.
2.2. В случае несогласия с условиями Политики Пользователь должен прекратить использование Сайта и Приложения. 
2.3. Настоящая Политика применяется исключительно к Сайту и Приложению, не распространяется на сайты третьих лиц.
3. Предмет Политики конфиденциальности
3.1. Администрация обязуется не раскрывать и обеспечивать защиту персональных и регистрационных данных Пользователей.
3.2. Данные, подлежащие обработке:
ФИО;
Номер телефона;
E-mail;
Адрес доставки;
Иные данные, необходимые для оформления и доставки заказа.
3.3. Приложение и Сайт автоматически собирают:
IP-адрес устройства;
Информацию из cookies;
Характеристики браузера и операционной системы.
4. Цели сбора персональной информации
4.1. Администрация использует данные для:
Идентификации Пользователя;
Обеспечения доступа к Сайту и Приложению;
Обратной связи с Пользователем;
Подтверждения достоверности данных;
Обработки и доставки заказов;
Улучшения качества обслуживания;
Информационных рассылок (при согласии Пользователя).
5. Способы и сроки обработки данных
5.1. Обработка данных осуществляется без ограничения срока любыми законными способами.
5.2. Пользователь соглашается на передачу данных аффилированным компаниям, курьерским службам и другим третьим лицам для выполнения заказа.
5.3. Данные могут быть переданы государственным органам в порядке, установленном законодательством Республики Казахстан.
5.4. Администрация принимает меры для защиты данных от несанкционированного доступа.
6. Права и обязанности сторон
6.1. Пользователь имеет право:
Самостоятельно принимать решение о предоставлении данных;
Обновлять и дополнять данные;
Требовать уточнения, блокирования или уничтожения данных;
Получать информацию об обработке своих данных.
6.2. Администрация обязуется:
Использовать данные только для указанных целей;
Обеспечивать конфиденциальность данных;
Принимать меры по защите данных;
Блокировать данные по запросу Пользователя или уполномоченного органа.
7. Ответственность сторон
7.1. Администрация не несет ответственности в случае утраты или разглашения данных, если они:
Стали публичным достоянием до разглашения;
Были получены от третьей стороны;
Были разглашены с согласия Пользователя. 7.2. Пользователь несет ответственность за соблюдение законодательства Республики Казахстан.
8. Разрешение споров
8.1. До обращения в суд необходимо предъявить письменную претензию.
8.2. Получатель претензии обязан в течение 30 дней сообщить о результатах ее рассмотрения.
8.3. Споры рассматриваются в судебных органах по месту нахождения ТОО "Partner’s Mountain Water Co".
8.4. К Политике применяется законодательство Республики Казахстан.
9. Дополнительные условия
9.1. Администрация вправе вносить изменения в Политику без согласия Пользователя.
9.2. Новая редакция Политики вступает в силу с момента ее размещения на Сайте и в Приложении.
9.3. Вопросы по Политике следует направлять по адресу: info@tibetskaya.kz.
9.4. Действующая Политика доступна по адресу: https://tibetskaya.kz/privacyPolicy.
10. Ограничение ответственности
10.1. ТОО "Partner’s Mountain Water Co" сделает все возможное для соблюдения настоящей политики конфиденциальности, однако, не может гарантировать сохранность информации в случае воздействия факторов, находящихся вне его влияния, результатом действия которых станет раскрытие информации. Сайт www.tibetskaya.kz и вся размещенная на нем информация представлены по принципу "как есть” без каких-либо гарантий и не несет ответственности за неблагоприятные последствия, а также за любые убытки, причиненные вследствие ограничения доступа к сайту URL или вследствие посещения сайта и использования размещенной на нем информации.

5. Контакты
По вопросам, касающимся настоящей политики, просьба обращаться по адресу info@tibetskaya.kz

Юридическое лицо

ТОО “Partner’s Mountain Water Co”
Республика Казахстан, г. Алматы,
ул. Шевченко, д. 7/75, н.п. 59а
Фактический адрес: мкр. Нурлытау,
ул. Г. Баязитовой, 12
БИН 090840006340
БАНК АО «Народный банк Казахстана»
ИИК KZ076017131000030995
БИК HSBKKZKX
КБЕ 17`

    const formattedPaymentTerms1 = paymentTerms1.split('\n').map((paragraph, index) => {
        if (paragraph.trim() === "") return null; // Пропускаем пустые строки
        return paragraph.startsWith(' ') ? <p key={index}>{paragraph.trim()}</p> : <h3 key={index}>{paragraph.trim()}</h3>;
    });
    const formattedPaymentTerms2 = paymentTerms2.split('\n').map((paragraph, index) => {
        if (paragraph.trim() === "") return null; // Пропускаем пустые строки
        return paragraph.startsWith(' ') ? <p key={index}>{paragraph.trim()}</p> : <h3 key={index}>{paragraph.trim()}</h3>;
    });

    return (
        <div className="flex flex-col min-h-screen justify-between">
            <div className="pt-5 px-5 space-y-3">
                <h2 className="text-lg font-bold">Условия оплаты, конфиденциальности и поставки</h2>

                {formattedPaymentTerms1}

                <div className="flex flex-col lg:flex-row lg:justify-center lg:gap-x-4 gap-y-2 my-2">
                    <img src="/images/PaymentTerms1.png" alt="Оплата" className="w-[200px] h-auto rounded-lg" />
                    <img src="/images/PaymentTerms2.png" alt="Оплата" className="w-[200px] h-auto rounded-lg" />
                    <img src="/images/PaymentTerms3.png" alt="Оплата" className="w-[200px] h-auto rounded-lg" />
                </div>
                {formattedPaymentTerms2}
            </div>

            <div className="">
                <Footer ref={footerRef} phone={phone} mail={mail} />
            </div>
        </div>
    );
}
