import React, { useRef, useState, useEffect } from "react";
import api from "../api";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

export default function PrivacyPolicy() {
    const { id } = useParams();

    const tel = id || "87475315558";

    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");

    useEffect(() => {
        const formData = new FormData();
        formData.append("phone", tel);

        api.post("/getFranchiseeByPhone", formData, {
            headers: { "Content-Type": "application/json" },
        })
            .then(({ data }) => {
                setPhone(
                    `+7 ${data.phone.substring(1, 4)} ${data.phone.substring(
                        4,
                        7
                    )} ${data.phone.substring(7, 9)} ${data.phone.substring(9)}`
                );
                setMail(data.mail);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [tel]);

    const footerRef = useRef(null);

    const privacyPolicy = `Настоящая политика конфиденциальности действует в отношении всей информации, которую интернет-проект www.tibetskaya.kz и клиентское приложение "Аквамаркет Тибетская" (далее – "Приложение") могут получить от Пользователя во время его использования.
1. Термины
1.1. "Сайт" — интернет-проект www.tibetskaya.kz, предоставляющий услуги Пользователям.
1.2. "Приложение" — клиентское приложение "Аквамаркет Тибетская" для заказа бутилированной воды, устанавливаемое на мобильные устройства Пользователя.
1.3. "Администрация" — лица, уполномоченные ТОО "Partner’s Mountain Water Co" на управление Сайтом и Приложением, которые организуют и/или осуществляют обработку персональных данных.
1.4. "Персональные данные" — сведения, относящиеся к определенному или определяемому физическому лицу.
1.5. "Регистрационные данные" — данные, предоставленные при регистрации в Приложении или на Сайте.
1.6. "Обработка данных" — накопление, хранение, изменение, использование, распространение, обезличивание, блокирование и уничтожение регистрационных/персональных данных.
1.7. "Cookies" — небольшие фрагменты данных, сохраняемые на устройстве Пользователя для улучшения работы Сайта и Приложения.
1.8. "IP-адрес" — уникальный сетевой адрес устройства Пользователя.
2. Общие положения
2.1. Использование Сайта или Приложения Пользователем означает согласие с настоящей Политикой конфиденциальности.
2.2. В случае несогласия с условиями Политики Пользователь должен прекратить использование Сайта и Приложения. 
2.3. Настоящая Политика применяется исключительно к Сайту и Приложению, не распространяется на сайты третьих лиц.
3. Предмет Политики конфиденциальности
3.1. Администрация обязуется не раскрывать и обеспечивать защиту персональных и регистрационных данных Пользователей.
3.2. Данные, подлежащие обработке:
ФИО;
Номер телефона;
E-mail;
Адрес доставки;
Иные данные, необходимые для оформления и доставки заказа.
3.3. Приложение и Сайт автоматически собирают:
IP-адрес устройства;
Информацию из cookies;
Характеристики браузера и операционной системы.
4. Цели сбора персональной информации
4.1. Администрация использует данные для:
Идентификации Пользователя;
Обеспечения доступа к Сайту и Приложению;
Обратной связи с Пользователем;
Подтверждения достоверности данных;
Обработки и доставки заказов;
Улучшения качества обслуживания;
Информационных рассылок (при согласии Пользователя).
5. Способы и сроки обработки данных
5.1. Обработка данных осуществляется без ограничения срока любыми законными способами.
5.2. Пользователь соглашается на передачу данных аффилированным компаниям, курьерским службам и другим третьим лицам для выполнения заказа.
5.3. Данные могут быть переданы государственным органам в порядке, установленном законодательством Республики Казахстан.
5.4. Администрация принимает меры для защиты данных от несанкционированного доступа.
6. Права и обязанности сторон
6.1. Пользователь имеет право:
Самостоятельно принимать решение о предоставлении данных;
Обновлять и дополнять данные;
Требовать уточнения, блокирования или уничтожения данных;
Получать информацию об обработке своих данных.
6.2. Администрация обязуется:
Использовать данные только для указанных целей;
Обеспечивать конфиденциальность данных;
Принимать меры по защите данных;
Блокировать данные по запросу Пользователя или уполномоченного органа.
7. Ответственность сторон
7.1. Администрация не несет ответственности в случае утраты или разглашения данных, если они:
Стали публичным достоянием до разглашения;
Были получены от третьей стороны;
Были разглашены с согласия Пользователя. 7.2. Пользователь несет ответственность за соблюдение законодательства Республики Казахстан.
8. Разрешение споров
8.1. До обращения в суд необходимо предъявить письменную претензию.
8.2. Получатель претензии обязан в течение 30 дней сообщить о результатах ее рассмотрения.
8.3. Споры рассматриваются в судебных органах по месту нахождения ТОО "Partner’s Mountain Water Co".
8.4. К Политике применяется законодательство Республики Казахстан.
9. Дополнительные условия
9.1. Администрация вправе вносить изменения в Политику без согласия Пользователя.
9.2. Новая редакция Политики вступает в силу с момента ее размещения на Сайте и в Приложении.
9.3. Вопросы по Политике следует направлять по адресу: info@tibetskaya.kz.
9.4. Действующая Политика доступна по адресу: https://tibetskaya.kz/privacyPolicy.
10. Ограничение ответственности
10.1. ТОО "Partner’s Mountain Water Co" сделает все возможное для соблюдения настоящей политики конфиденциальности, однако, не может гарантировать сохранность информации в случае воздействия факторов, находящихся вне его влияния, результатом действия которых станет раскрытие информации. Сайт www.tibetskaya.kz и вся размещенная на нем информация представлены по принципу "как есть” без каких-либо гарантий и не несет ответственности за неблагоприятные последствия, а также за любые убытки, причиненные вследствие ограничения доступа к сайту URL или вследствие посещения сайта и использования размещенной на нем информации.
`

    const formattedPolicy = privacyPolicy.split('\n').map((paragraph, index) => {
        if (paragraph.trim() === "") return null; // Пропускаем пустые строки
        return paragraph.startsWith(' ') ? <p key={index}>{paragraph.trim()}</p> : <h3 key={index}>{paragraph.trim()}</h3>;
    });

    return (
        <div className="flex flex-col min-h-screen justify-between">
            <div className="pt-5 px-5 space-y-3">
                <h2 className="text-lg font-bold">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
                {formattedPolicy}
            </div>

            <div className="">
                <Footer ref={footerRef} phone={phone} mail={mail} />
            </div>
        </div>
    );
}
