import React, { useRef, useState, useEffect } from "react";
import api from "../api";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

export default function PrivacyPolicy() {
    const { id } = useParams();

    const tel = id || "87475315558";

    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");

    useEffect(() => {
        const formData = new FormData();
        formData.append("phone", tel);

        api.post("/getFranchiseeByPhone", formData, {
            headers: { "Content-Type": "application/json" },
        })
            .then(({ data }) => {
                setPhone(
                    `+7 ${data.phone.substring(1, 4)} ${data.phone.substring(
                        4,
                        7
                    )} ${data.phone.substring(7, 9)} ${data.phone.substring(9)}`
                );
                setMail(data.mail);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [tel]);

    const footerRef = useRef(null);

    const publicOffer = `1. Общие положения и термины
1.1. Настоящее Соглашение является публичной офертой (в соответствии со ст. 447 Гражданского кодекса Республики Казахстан) ТОО «Partner’s Mountain Water Co» (далее – «Аквамаркет Тибетская», «Мы» или «Продавец») в адрес любых дееспособных физических или юридических лиц (далее – «Пользователь» или «Вы») о заключении договора на условиях, изложенных в настоящем документе.
1.2. Акцептом (принятием) настоящей Оферты является любое из следующих действий Пользователя:
Регистрация и/или авторизация в мобильном приложении или на сайте «Аквамаркет Тибетская»;
Фактическое оформление Заказа на воду «Тибетская» с использованием функционала приложения или сайта;
Любое иное подтверждение согласия с условиями настоящего Соглашения.
1.3. Приложение «Аквамаркет Тибетская» (далее – «Приложение») – программное обеспечение (веб-сайт и/или мобильное приложение), предназначенное для заказа и доставки бутилированной воды «Тибетская» объемами 18,9 и 12,5 литров (далее – «Товар»).
1.4. Пользователь – физическое или юридическое лицо, совершающее Заказы в Приложении и/или использующее функционал Приложения для личных или корпоративных нужд.
1.5. Профиль (личный кабинет) – защищенный раздел Приложения, доступны после регистрации/авторизации Пользователя, содержащий персональные данные Пользователя, историю Заказов, контактную информацию, а также иные данные, необходимые для использования Приложения.
1.6. Заказ – совокупность действий, совершенных Пользователем в Приложении и направленных на приобретение Товара «Тибетская» с доставкой или самовывозом (если предусмотрено).
1.7. Стороны – совместно Продавец (ТОО “Partner’s Mountain Water Co”) и Пользователь.

2. Предмет Соглашения
2.1. Настоящим Соглашением определяются условия предоставления Продавцом (через Приложение) доступа к функционалу для оформления и оплаты Заказов на покупку бутилированной воды «Тибетская» (Товар), а также условия доставки и получения данного Товара.
2.2. Используя Приложение и оформляя Заказы, Пользователь соглашается со всеми положениями настоящего Соглашения, а также подтверждает, что полностью понимает его содержание и правовые последствия.

3. Регистрация и персональные данные
3.1. Для оформления Заказов и доступа к полному функционалу Приложения Пользователь проходит процедуру регистрации или авторизации (если уже зарегистрирован).
3.2. При регистрации в Приложении Пользователь предоставляет достоверные данные, включая (но не ограничиваясь):
ФИО (или наименование организации);
Номер телефона;
Электронный адрес (E-mail);
Иные данные, необходимые для оформления Заказа и доставки.
3.3. Нажимая кнопку «Зарегистрироваться», «Принять», «Оформить заказ» и пр., Пользователь выражает безоговорочное согласие с условиями настоящего Соглашения и Политикой конфиденциальности Приложения (при наличии отдельного документа).
3.4. Пользователь обязан следить за актуальностью предоставленных данных. В случае изменения какой-либо информации Пользователь в кратчайшие сроки обновляет её в Личном кабинете или иным образом уведомляет Продавца.
3.5. Приложение может использовать предоставленные Пользователем персональные данные для:
Обработки Заказов и организации доставки;
Связи с Пользователем (звонки, SMS, E-mail, пуш-уведомления);
Направления информационных и рекламных материалов в пределах, установленных законодательством Республики Казахстан;
Иных действий, предусмотренных действующим законодательством и внутренними политиками «Аквамаркет Тибетская».
3.6. Согласие на обработку персональных данных действует бессрочно и может быть отозвано Пользователем посредством письменного заявления, направленного на контактный адрес Продавца. При этом отзыв согласия может повлечь невозможность дальнейшего использования Приложения.

4. Оформление и оплата Заказа
4.1. Товар – бутилированная вода «Тибетская» в бутылях объемом 18,9 и/или 12,5 литров, доступная для покупки через Приложение.
4.2. Оформление Заказа происходит следующим образом:
Пользователь авторизуется в Приложении;
Выбирает нужное количество бутилированной воды «Тибетская»;
Указывает адрес доставки (или выбирает пункт самовывоза, если такая опция предусмотрена);
Подтверждает Заказ и выбирает способ оплаты.
4.3. Оплата Товара может осуществляться любым из указанных в Приложении способов (наличными при получении, онлайн-оплата через банк/платежную систему и пр.), если иной порядок не предусмотрен отдельными соглашениями или функционалом Приложения.
4.4. Цены на Товар указываются в Приложении и могут меняться в одностороннем порядке Продавцом. Стоимость Товара действительна на момент оформления Заказа и не может быть изменена после его подтверждения Пользователем.
4.5. Заказ считается оформленным и принятым в работу после подтверждения Приложением статуса Заказа (например, путем отображения соответствующего сообщения в интерфейсе Приложения и/или отправки уведомления Пользователю).

5. Доставка и передача Товара
5.1. Продавец организует доставку Товара по адресу, указанному Пользователем в пределах города Алматы, либо предоставляет возможность самовывоза (если предусмотрено).Сроки и время доставки Пользователь выбирает самостоятельно при оформлении Заказа (из доступных вариантов), и они указываются в соответствующем Приложении.
5.2. Сроки доставки носят ориентировочный характер и могут меняться ввиду обстоятельств, на которые Продавец не может влиять (пробки, погодные условия и т. д.).
5.3. При получении Товара Пользователь обязан проверить целостность упаковки и соответствие количества бутылей оформленному Заказу. В случае обнаружения явных повреждений упаковки или несоответствий по количеству Пользователь вправе отказаться от приемки такого Товара, составив соответствующий акт вместе с представителем службы доставки.
5.4. Право собственности на Товар переходит к Пользователю в момент его фактической передачи и подписания сопроводительных документов (при необходимости). С этого момента к Пользователю переходит и риск случайной гибели или порчи Товара.

6. Гарантии и ответственность
6.1. Продавец гарантирует соответствие Товара требованиям действующего законодательства Республики Казахстан и санитарно-гигиеническим нормам СТ РК 1432-2005, подтверждающим качество питьевой воды.
6.2. В случае обнаружения скрытых недостатков Товара после его передачи Пользователю, Пользователь вправе предъявить соответствующие претензии в сроки и порядке, предусмотренные законодательством Республики Казахстан.
6.3. Претензии по качеству Товара принимаются в письменном или электронном виде (например, по адресу электронной почты, указанному в разделе контактов Приложения).
6.4. Продавец не несет ответственность за любой прямой или косвенный ущерб, возникший у Пользователя по причине:
Нарушения Пользователем условий настоящего Соглашения;
Несанкционированного доступа третьих лиц к учетной записи Пользователя;
Технических сбоев, прерываний работы Приложения, вызванных обстоятельствами, не зависящими от Продавца (сбои на стороне операторов связи, провайдеров хостинга и пр.).
6.5. Пользователь несёт ответственность за достоверность и актуальность предоставляемых при регистрации и оформлении Заказов данных, а также за сохранность своих учетных данных (логин, пароль и т. д.).

7. Форс-мажор
7.1. Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по настоящему Соглашению, если оно явилось следствием форс-мажорных обстоятельств (стихийные бедствия, военные действия, забастовки, действия органов власти и иные обстоятельства, не зависящие от воли Сторон).
7.2. Сторона, для которой выполнение обязательств стало невозможным из-за форс-мажорных обстоятельств, обязана уведомить другую Сторону в течение 5 (пяти) календарных дней с момента их наступления.
7.3. Если форс-мажорные обстоятельства продолжаются более 30 (тридцати) дней, каждая из Сторон вправе в одностороннем порядке отказаться от дальнейшего исполнения Соглашения, при этом ни одна из Сторон не вправе требовать от другой возмещения возможных убытков, вызванных форс-мажором.

8. Изменение и расторжение Соглашения
8.1. Настоящее Соглашение может быть изменено Продавцом в одностороннем порядке без предварительного уведомления Пользователя. Новая редакция Соглашения вступает в силу с момента размещения в Приложении или на официальном сайте «Аквамаркет Тибетская», если иной срок не указан дополнительно.
8.2. Продолжение использования Приложения после внесения изменений в Соглашение означает согласие Пользователя с новой редакцией.
8.3. Пользователь вправе в любой момент удалить свою учетную запись (профиль) через окно “Профиль” (личный кабинет) или направив письменный запрос Продавцу. Удаление профиля не освобождает Пользователя от исполнения уже подтвержденных Заказов и обязательств по оплате.
8.4. Продавец вправе заблокировать или удалить учетную запись Пользователя в случае нарушения Пользователем условий настоящего Соглашения или законодательства Республики Казахстан, а также при наличии признаков мошенничества или недобросовестного пользования Приложением.

9. Разрешение споров
9.1. Все споры и разногласия, возникающие из настоящего Соглашения или в связи с ним, подлежат урегулированию путем переговоров и направления претензий по адресу: info@tibetskaya.kz или иному адресу, указанному в Приложении.
9.2. В случае недостижения согласия путем переговоров спор может быть передан на рассмотрение в суд по месту нахождения ТОО «Partner’s Mountain Water Co» (Вода ТМ Тибетская) в соответствии с законодательством Республики Казахстан.

10. Прочие условия
10.1. Настоящее Соглашение, а также порядок и условия использования Приложения регулируются действующим законодательством Республики Казахстан.
10.2. Признание какого-либо пункта или части настоящего Соглашения недействительным не влечет недействительности остальных положений.
10.3. Продавец вправе передавать свои права и обязанности по настоящему Соглашению (полностью или частично) третьим лицам без согласия Пользователя.
10.4. Во всём остальном, что прямо не урегулировано настоящим Соглашением, Стороны руководствуются действующим законодательством Республики Казахстан.
10.5. Текст настоящего Соглашения опубликован в Приложении и/или на официальном сайте «Аквамаркет Тибетская» в сети Интернет по адресу: www.tibetskaya.kz/PublicOffer.
10.6. Настоящее Соглашение включает в себя условия пользовательского соглашения и политику конфиденциальности (при наличии отдельной политики), с которыми Пользователь соглашается при акцепте настоящей Оферты.

РЕКВИЗИТЫ ПРОДАВЦА
ТОО «Partner’s Mountain Water Co»
БИН: 090840006340
Юридический адрес: г. Алматы, ул. Шевченко, 7, оф. 59А
Тел.: +7 727 3172737
E-mail: info@tibetskaya.kz
Дата последнего обновления: «11» февраля 2025 г.

`

    const formattedPublicOffer = publicOffer.split('\n').map((paragraph, index) => {
        if (paragraph.trim() === "") return null; // Пропускаем пустые строки
        return paragraph.startsWith(' ') ? <p key={index}>{paragraph.trim()}</p> : <h3 key={index}>{paragraph.trim()}</h3>;
    });

    return (
        <div className="flex flex-col min-h-screen justify-between">
            <div className="pt-5 px-5 space-y-3">
                <h2 className="text-lg font-bold">ПУБЛИЧНАЯ ОФЕРТА (ПРЕДЛОЖЕНИЕ) ПРИЛОЖЕНИЯ «АКВАМАРКЕТ ТИБЕТСКАЯ»</h2>
                {formattedPublicOffer}
            </div>

            <div className="">
                <Footer ref={footerRef} phone={phone} mail={mail} />
            </div>
        </div>
    );
}
